import React from "react";
import { string, object, bool } from "prop-types";
import { Link } from "@quintype/components";

import "./menu-item.m.css";
import { addClickTrackingToDataLayer } from "../../helper/datalayer-utils";

const MenuItem = ({
  type = "primary",
  item,
  toggleHandler,
  menuStyle = "menu-link",
  changeTextWeight = false,
  changeTextColor = false
}) => {
  const handleMenuClick = (e) => {
    window.dataLayer = window.dataLayer || [];
    addClickTrackingToDataLayer(
      item?.url || "/",
      item.title,
      type === "primary" ? "header-l1" : type === "secondary" ? "header-l2" : "hamburger-menu"
    );
  };

  return (
    <div onClick={handleMenuClick} styleName={`${type}-menu-link-wrapper`} className="menu-link-wrapper">
      <Link
        styleName={`${menuStyle} ${changeTextColor ? "special-text" : ""} ${
          changeTextWeight ? "special-text-bold" : ""
        }`}
        aria-label="menu-item"
        callback={toggleHandler}
        className={menuStyle}
        menu-title={item.title}
        href={item?.url ? item.url : "/"}
        externalLink={item.isExternalLink && item?.url}
        style={{ color: `${changeTextColor && item?.data?.color ? item?.data?.color : ""}` }}
      >
        {item.title}
      </Link>
    </div>
  );
};

MenuItem.propTypes = {
  type: string,
  item: object.isRequired,
  menuStyle: string,
  changeTextWeight: bool,
  changeTextColor: bool
};

export { MenuItem };
